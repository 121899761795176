import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import ProgramMentoring from '../../../images/logos/inhub/program_mentoringowy.png';
import { HeroLogo } from '../../from-science-to-startup/styled.components';

const HeroSection = () => {
  const {t} = useTranslation();

  return (
    <section className="page-section registration-form-hero">
      <div className="container">
        <div className="text-ctn">
          <HeroLogo
            src={ProgramMentoring}
            alt="Program Mentorignowy Logo"
          />
          <h1>
            {t`register_form.title`}
            <br/>
            <span>{t`register_form.subtitle`}</span>
          </h1>
          {/*<p>*/}
          {/*  {t`register_form.info`}{' '}*/}
          {/*  <a target="_blank" rel="noopener noreferrer" href="/mentoring-program">{t`register_form.info2`}</a>{' '}*/}
          {/*  {t`register_form.info3`}*/}
          {/*</p>*/}
        </div>
      </div>
    </section>
  );
}

export default HeroSection
