import { useTranslation } from 'gatsby-plugin-react-i18next';
import Seo from '../../components/seo';
import React from 'react';
import Layout from '../../components/layout/layout';
import HeroSection from './components/hero-section';
import './style.scss'
import FormSection from './components/form-section';


const RegistrationForm = () => {
  const {t} = useTranslation();

  return (
    <Layout withHeader={false}>
      <Seo
        title={t`register_form.title`}
        description={t`register_form.info`}
        // ogImage={ogImage}
      />

      {/*<Theme1/>*/}
      {/*<Theme2/>*/}
      {/*<Theme3/>*/}

      <HeroSection/>

      <FormSection/>
    </Layout>
  )
}

const Theme1 = () => (
  <div className="register-form-theme1">
    <svg xmlns="http://www.w3.org/2000/svg" width="119" height="110" viewBox="0 0 119 110" fill="none">
      <path d="M50.7687 82.2861C50.7687 94.3322 40.6648 104.35 27.8843 104.35C15.1039 104.35 5 94.3322 5 82.2861C5 70.2401 15.1039 60.2219 27.8843 60.2219C40.6648 60.2219 50.7687 70.2401 50.7687 82.2861Z" stroke="#0F5575" strokeWidth="10"/>
      <ellipse cx="74.0847" cy="13.6688" rx="13.9422" ry="13.6688" fill="#0F5575"/>
      <circle cx="115.911" cy="2.73376" r="2.73376" fill="#0F5575"/>
      <ellipse cx="80.9203" cy="58.776" rx="7.10777" ry="6.8344" fill="#0F5575"/>
    </svg>
  </div>
)

const Theme2 = () => (
  <div className="register-form-theme2">
    <svg width="145" height="307" viewBox="0 0 145 307" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M143.5 147.738C143.5 160.689 132.783 171.238 119.5 171.238C106.217 171.238 95.5 160.689 95.5 147.738C95.5 134.787 106.217 124.238 119.5 124.238C132.783 124.238 143.5 134.787 143.5 147.738Z" fill="#0F5575" stroke="#0F5575" strokeWidth="3"/>
      <path d="M116.5 53.5C116.5 81.0534 93.7279 103.5 65.5 103.5C37.2721 103.5 14.5 81.0534 14.5 53.5C14.5 25.9466 37.2721 3.5 65.5 3.5C93.7279 3.5 116.5 25.9466 116.5 53.5Z" stroke="#0F5575" strokeWidth="7"/>
      <circle cx="24" cy="236.738" r="70" fill="#0F5575"/>
    </svg>
  </div>
)

const Theme3 = () => (
  <div className="register-form-theme3">
    <svg width="221" height="351" viewBox="0 0 221 351" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M118.868 182.386C125.344 193.601 121.337 208.096 109.834 214.737C98.3303 221.379 83.7745 217.601 77.299 206.386C70.8236 195.17 74.8303 180.676 86.3336 174.034C97.837 167.393 112.393 171.17 118.868 182.386Z" fill="#5FA5C5" stroke="#5FA5C5" strokeWidth="3"/>
      <path d="M118.116 69.0824C131.892 92.9443 123.394 123.77 98.9484 137.884C74.5023 151.998 43.5578 143.944 29.7811 120.082C16.0044 96.2204 24.5023 65.395 48.9484 51.2811C73.3945 37.1671 104.339 45.2204 118.116 69.0824Z" stroke="#5FA5C5" strokeWidth="7"/>
      <circle cx="193.648" cy="248.908" r="70" transform="rotate(-30 193.648 248.908)" fill="#5FA5C5"/>
    </svg>
  </div>
)

export default RegistrationForm
